<template>
  <div class="main">
    <div row v-if="error">
      <div >
        <div class="app-alert" @dismissed="onDismissed" :text="error.message"></div>
      </div>
    </div>
    <div row>
      <div >
        <div>
          <div>
            <div>
              <form @submit.prevent="onSignup">
                <div row>
                  <div >
                    <input
                      name="email"
                      label="Email"
                      id="email"
                      placeholder="email"
                      v-model="email"
                      type="email"
                      required>
                  </div>
                </div>
                <div row>
                  <div >
                    <input
                      name="password"
                      label="Password"
                      id="password"
                      placeholder="password"
                      v-model="password"
                      type="password"
                      required>
                  </div>
                </div>
                <div row>
                  <div >
                    <input
                      name="confirmPassword"
                      label="Confirm Password"
                      id="confirmPassword"
                      placeholder="password again"
                      v-model="confirmPassword"
                      type="password"
                      :rules="[comparePasswords]">
                  </div>
                </div>
                <div row>
                  <div >
                    <div class="text-xs-center">
                    <button round type="submit" :disabled="loading" :loading="loading">
                      Sign up
  
                      <span slot="loader" class="custom-loader">
                        <!-- <div class="v-icon" light>cached</div> -->
                       </span>
                    </button>
                    </div>

                    <div class="signin-options" style="display:none;">
                      <button round color="red" dark :disabled="loading" :loading="loading" @click.prevent="onSigninGoogle">Login with Google
                        <div class="v-icon" right dark>lock_open</div>
                        <span slot="loader" class="custom-loader">
                          <div class="v-icon" light>cached</div>
                       </span>
                      </button>

                      <button round color="primary" dark :disabled="loading" :loading="loading" @click.prevent="onSigninFacebook">Login with Facebook
                        <div class="v-icon" right dark>lock_open</div>
                        <span slot="loader" class="custom-loader">
                          <div class="v-icon" light>cached</div>
                       </span>
                      </button>

                      <button round dark :disabled="loading" :loading="loading" @click.prevent="onSigninGithub">Login with Github
                        <div class="v-icon" right dark>lock_open</div>
                        <span slot="loader" class="custom-loader">
                          <div class="v-icon" light>cached</div>
                       </span>
                      </button>

                      <button round color="info" dark :disabled="loading" :loading="loading" @click.prevent="onSigninTwitter">Login with Twitter
                        <div class="v-icon" right dark>lock_open</div>
                        <span slot="loader" class="custom-loader">
                          <div class="v-icon" light>cached</div>
                       </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        email: '',
        password: '',
        confirmPassword: ''
      }
    },
    computed: {
      comparePasswords () {
        return this.password !== this.confirmPassword ? 'Passwords do not match' : ''
      },
      user () {
        return this.$store.getters.user
      },
      error () {
        return this.$store.getters.error
      },
      loading () {
        return this.$store.getters.loading
      }
    },
    watch: {
      user (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/profile')
        }
      }
    },
    methods: {
      onSignup () {
        this.$store.dispatch('signUserUp', {email: this.email, password: this.password})
      },
      onSigninGoogle () {
        this.$store.dispatch('signUserInGoogle')
      },
      onSigninFacebook () {
        this.$store.dispatch('signUserInFacebook')
      },
      onSigninGithub () {
        this.$store.dispatch('signUserInGithub')
      },
      onSigninTwitter () {
        this.$store.dispatch('signUserInTwitter')
      },
      onDismissed () {
        this.$store.dispatch('clearError')
      }
    }
  }
</script>

<style lang="scss" scoped>
.main {
  justify-content: center;
}
</style>